<template>
  <div class="page">
    <div class="header" id="navbar">
      <div class="navbar navbar-mobile d-flex justify-content-between d-md-none">
        <a href="/#/"><img class="logo d-md-none" alt="Logo" title="Logo" src="@/assets/pocketyard_logo.svg" /></a>
        <a href="/#/"><img class="logo d-none d-md-inline" alt="Logo" title="Logo" src="@/assets/pocketyard_logo_black.svg" /></a>
        <img
          class="hamburger text-white"
          src="@/assets/Landing/hamburger.svg"
          @click="isSidebarOpen = true"
        />
      </div>
      <div class="navbar d-none d-md-flex">
        <a href="/#">
          <img
            class="logo"
            alt="Logo"
            title="Logo"
            src="@/assets/pocketyard_logo.svg"
          />
        </a>
        <div class="buttons">
          <button class="btn login mr-3" @click="$router.push('login')">LOGIN</button>
          <button class="btn signup" @click="$router.push('register')">SIGN UP</button>
        </div>
      </div>
      <LandingSidebarVue :isOpen="isSidebarOpen" @close="isSidebarOpen = false" activeItem="home"/>
      <div class="hero">
        <div class="texts">
          <h1 class="d-none d-md-block">Digitizing the construction marketplace.</h1>
          <h1 class="d-block d-md-none">Digitizing the construction marketplace.</h1>
          <p class="d-none d-md-block">
            Our platform serves as a digital bridge, connecting vendors and<br />buyers
            in the construction industry.
          </p>
          <p class="d-block d-md-none">
            Our platform serves as a digital bridge,<br />connecting vendors and buyers
            in the<br />construction industry.
          </p>
        </div>
        <div class="buttons d-flex d-md-block">
          <button class="btn buyer" @click="$router.push('buyer')">Buyers</button>
          <button class="btn vendor" @click="$router.push('vendor')">Vendors</button>
        </div>
      </div>
    </div>

    <div class="body">
      <div class="list">
        <div class="heading">
          <h3>Let's cut to the quick.</h3>
          <p>We offer transformative solutions.</p>
          <div class="line">&nbsp;</div>
        </div>
        <div class="items d-flex flex-column flex-md-row justify-contents-between">
          <div class="item">
            <img src="@/assets/Landing/information-icon.svg">
            <h6>About Pocketyard</h6>
            <p>Pocketyard is exactly what you need, precisely when you need it, minus the typical construction supply procurement chaos. </p>
          </div>
          <div class="item">
            <img src="@/assets/Landing/performance-icon.svg">
            <h6>Our Performance</h6>
            <p>More convenient, faster, smarter, smoother. Obtaining crucial building materials for your site is as simple as Choose, Order... Build!</p>
          </div>
          <div class="item">
            <img src="@/assets/Landing/result-icon.svg">
            <h6>Final Results</h6>
            <p>Buyers and estimators love pocketyard because receiving several vendor bids at once results in substantial savings of both time and money. </p>
          </div>
        </div>
      </div>
      <div class="simplification">
        <div class="heading">
          <h3>Simplification is a key</h3>
          <p>Streamlining the procurement process like never before.</p>
          <div class="line">&nbsp;</div>
        </div>
        <div class="items d-flex justify-content-between flex-column flex-md-row">
          <div class="item buyer">
            <div class="banner-container">
              <a href="/#/buyer">
                <img class="banner" src="@/assets/Landing/simplification-buyer.png">
              </a>
            </div>
            <div class="title d-flex">
              <img src="@/assets/Landing/buyer-icon.svg">
              <h3>For Buyers</h3>
            </div>
            <p>Buyers can effortlessly explore a wide range of materials, compare prices, and make purchases or request quotations, all in one place. </p>
            <router-link :to="{ name: 'Buyer' }" class="learn">Learn More <img src="@/assets/Landing/arrow.svg" /></router-link>
          </div>
          <div class="item vendor">
            <div class="banner-container">
              <a href="/#/vendor">
                <img class="banner" src="@/assets/Landing/simplification-vendor.png">
              </a>
            </div>
            <div class="title d-flex">
              <img src="@/assets/Landing/vendor-icon.svg">
              <h3>For Vendors</h3>
            </div>
            <p>Vendors have a platform to showcase their products, manage their transactions, and engage with buyers efficiently.</p>
            <router-link :to="{ name: 'Vendor' }" class="learn">Learn More <img src="@/assets/Landing/arrow.svg" /></router-link>
          </div>
        </div>
      </div>

      <div class="marketplace">
        <div class="heading">
          <h3>Pocketyard is more than Just a marketplace</h3>
          <div class="line">&nbsp;</div>
        </div>
        <div class="content d-flex justify-content-between flex-column flex-md-row">
          <div class="iphone">
            <div class="iphone-container">
              <img class="d-none d-md-block" src="@/assets/Landing/iphone13.png">
              <img class="d-block d-md-none" src="@/assets/Landing/mobile-iphone13.png">
            </div>
          </div>
          <div class="items">
            <div class="item community">
              <h6 class="title">It's a Community</h6>
              <p>Fosters transparency, efficiency, and innovation in the construction industry.</p>
            </div>
            <div class="item construction">
              <h6 class="title">New Era of Construction</h6>
              <p>Step into a new era of construction material procurement with Pocketyard today.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LandingFooter activeItem="homepage" />
  </div>
</template>

<script>
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import LandingSidebarVue from '@/shared/elements/LandingSidebar.vue';
import LandingFooter from '@/shared/elements/LandingFooter.vue';


export default {
  name: 'Landing',

  mixins: [_appApiHelper],

  components: {
    LandingSidebarVue,
    LandingFooter,
  },

  data() {
    return {
      isSidebarOpen: false,
    };
  },
  mounted() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      document.getElementById('navbar').scrollIntoView({ behavior: 'smooth' });
    },
    scrollToDetails() {
      document.getElementById('details').scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_breakpoints';

.header {
  height: 100vh;
  background: url(~@/assets/Landing/LandingBackground.png);
  background-color: #282828;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @include media-md('below') {
    background: url(~@/assets/Landing/mobile-header.png);
  }

  .navbar-mobile {
    background-color: #282828;
  }

  .navbar {
    padding-left: 8.59vw;
    padding-right: 8.59vw;
    @include media-md('below') {
      padding-left: 15px;
      padding-right: 15px;
      img {
        height: 41px;
      }

      .hamburger {
        height: 24px;
      }
    }
    .buttons {
      font-family: Inter;
      font-weight: 600;
      font-size: 16px;

      button {
        width: 162px;
        height: 48px;
      }
      .login {
        background-color: #F38725;
        color: white;

        &:hover {
          background-color: #E67200;
        }
      }

      .signup {
        background-color: #28282899;
        color: #F59F51;

        &:hover {
          background-color: #282828;
          border: 1px solid #F38725;
        }
      }
    }
  }

  .hero {
    text-align: center;
    @include media-md('below') {
      text-align: left;
      padding-left: 15px;
      padding-right: 15px;
    }
    .texts {
      margin-top: 11vh;
      @include media-md('below') {
        margin-top: 25vh;
      }
      h1 {
        color: #F1F1F1;
        text-transform: capitalize;
        font-family: Oswald;
        font-weight: 600;
        font-size: 64px;
        line-height: 89.6px;
        letter-spacing: 0.5px;
        @include media-md('below') {
          color: #FCFCFC;
          font-family: Inter;
          font-size: 2em;
          font-weight: 800;
          line-height: 39px;
          letter-spacing: 0.8px;
        }
      }
      p {
        color: #F1F1F1;
        margin-top: 20px;
        line-height: 33.6px;
        font-family: Inter;
        font-weight: 400;
        font-size: 24px;
        @include media-md('below') {
          margin-top: 30px;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 0.2px;
        }
      }
    }
    .buttons {
      margin-top: 9.24vh;
      @include media-md('below') {
        padding-right: 47px;
        margin-top: 85px;
        height: 48px;
      }
      button {
        width: 12.5vw;
        height: 6.1664vh;
        font-family: Inter;
        font-weight: 600;
        line-height: 25.2px;
        font-size: 18px;
        text-transform: uppercase;
        @include media-md('below') {
          width: 31.88vw;
          font-size: 16px;
          height: auto;
        }
      }
      .buyer {
        background-color: #F38725;
        color: #333333;
        margin-right: 30px;
        &:hover {
          background-color: #E67200;
          box-shadow: 0px 4px 8px 0px #3031331A;
          box-shadow: 0px 0px 1px 0px #3031330D;
        }
      }
      .vendor {
        background-color: #28282880;
        border: 2px solid #F38725;
        color: #F38725;
        @include media-md('below') {
          width: 35.265vw;
        }
        &:hover {
          background-color: #282828;
          box-shadow: 0px 4px 24px 0px #00000040;
          border: 1px solid #F38725;
        }
      }
    }
  }
}
.body {
  color: #4F4F4F;
  .heading {
    text-align: center;
    font-family: Inter;
    padding-bottom: 60px;
    @include media-md('below') {
      text-align: left;
      padding-left: 15px;
      padding-right: 15px;
    }
    h3 {
      font-family: inherit;
      color: #282828;
      letter-spacing: 0.5px;
      text-transform: capitalize;
      font-size: 56px;
      font-weight: 800;
      line-height: 78px;
      @include media-md('below') {
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.2px;
      }
    }
    p {
      font-family: inherit;
      padding-top: 16px;
      padding-bottom: 16px;
      font-weight: 500;
      font-size: 24px;
      line-height: 33.6px;
      @include media-md('below') {
        padding-top: 8px;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.20000000298023224px;
        text-align: left;

      }
    }
    .line {
      margin-left: auto;
      margin-right: auto;
      width: 100px;
      height: 5px;
      background-color: #E67200;
      border-radius: 5px;
      @include media-md('below') {
        margin-left: 0px;
      }
    }
  }
  .list {
    padding-top: 16.7vh;
    padding-bottom: 16.7vh;
    background-color: #F6F6F6;

    .items {
      width: 71.875vw;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      @include media-md('below') {
        width: 100%;
        text-align: left;
      }
      .item {
        padding: 40px;
        width: 33.33%;
        font-family: Inter;
        @include media-md('below') {
          width: 100%;
        }
        img {
          width: 64px;
          height: 64px;
          margin-bottom: 24px;
        }
        h6 {
          font-family: inherit;
          font-weight: 700;
          line-height: 33.6px;
          font-size: 24px;
          margin-bottom: 24px;
          @include media-md('below') {
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.2px;
          }
        }
        p {
          font-family: inherit;
          font-weight: 400;
          line-height: 27px;
          font-size: 18px;
          @include media-md('below') {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.2px;
          }
        }
      }
      .item:first-child {
        margin-right: 30px;
        @include media-md('below') {
          margin-right: 0px;
        }
      }
      .item:last-child {
        margin-left: 30px;
        @include media-md('below') {
          margin-left: 0px;
        }
      }
    }
  }
  .simplification {
    padding-top: 7.399vh;
    padding-bottom: 7.399vh;
    .items {
      padding-left: 8.541vw;
      padding-right: 8.541vw;
    }

    .item {
      padding: 3.125vw;
      width: 50%;
      box-shadow: 0px 2px 14px 0px #0000000F;
      &:hover {
        box-shadow: 0px 14px 24px 0px #0000000F;
      }
      &:hover > .banner-container .banner {
          transform: scale(1.15);
      }
      &:hover > .learn {
        font-weight: 600;
      }
      @include media-md('below') {
        width: 100%;
        padding: 40px 30px;
      }

      .learn {
        img {
          margin-left: 8px;
          margin-bottom: 3px;
        }
      }

      .banner-container {
        overflow: hidden;
        border-radius: 8px;
        margin-bottom: 3.125vw;
        @include media-md('below') {
          margin-bottom: 40px;
        }
      }

      .banner {
        width: 100%;
        border-radius: 8px;
        transition: transform 0.4s;
      }

      .title {
        margin-bottom: 32px;
        img {
          width: 48px;
          margin-right: 18px;
          @include media-md('below') {
            width: 30px;
            margin-right: 16px;
          }
        }
        h3 {
          font-family: Oswald;
          font-size: 32px;
          font-weight: 600;
          line-height: 48px;
          @include media-md('below') {
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.8px;
          }
        }
      }
      p {
        font-family: Inter;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 62px;
        @include media-md('below') {
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0.2px;
          margin-bottom: 30px;
        }
      }
      a {
        color: #F38725;
        font-family: Inter;
        font-weight: 400;
        line-height: 30px;
        font-size: 20px;
        text-decoration: none;
        letter-spacing: 0.2px;
        @include media-md('below') {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.2px;
        }
      }
    }

    .buyer {
      margin-right: 15px;
      @include media-md('below') {
        margin-right: 0px;
        margin-bottom: 20px;
        padding-bottom: 20px;
      }
    }

    .vendor {
      margin-left: 15px;
      @include media-md('below') {
        margin-left: 0px;
        margin-bottom: 20px;
        padding-bottom: 20px;
      }
    }
  }
  .marketplace {
    padding-top: 17.06vh;
    padding-bottom: 17.06vh;
    background-color: #F6F6F6;
    .content {
      padding-right: 10.88vw;
      padding-left: 10.88vw;
      @include media-md('below') {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
    .iphone {
      padding-bottom: 5px;
      text-align: center;
      img {
        margin-left: auto;
        margin-right: auto;
        @include media-md('below') {
          width: 100%;
          margin-bottom: 156px;
        }
      }
    }
    .items {
      width: 29.01vw;
      @include media-md('below') {
        width: 100%;
      }
      .community {
        margin-bottom: 6.16vh;
      }
      .item {
        font-family: Inter;
        padding-right: 1.25vw;
        h6 {
          font-family: inherit;
          color: #282828;
          font-weight: 800;
          font-size: 30px;
          line-height: 39px;
          font-family: Inter;
          letter-spacing: 0.8px;
          @include media-md('below') {
            font-size: 24px;
            line-height: 31px;
            letter-spacing: 0.24px;
          }
        }
        p {
          font-family: inherit;
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          letter-spacing: 0.8px;
          @include media-md('below') {
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.2px;
          }
        }
      }
    }
  }
}
</style>
